import React from 'react';

import Article from './components/Article';
import Subtitle from './components/Subtitle';

import pic01 from '../../images/FredAggHuddle.jpg';

export default articleProps => {
  return (
    <Article id="intro" heading="Intro" image={pic01} {...articleProps}>
      <Subtitle>Teamwork is the most valuable design pattern</Subtitle>
      <p>
        Through my career I've found that the most important skill when it comes
        to building a good code base, in all aspects of the definition, is
        teamwork. To boil this down, trust, and honesty are the two broadest
        factors to build robust and maintainable code bases. Trust in yourself
        and your team, honesty with yourself and your team.
      </p>
      <p>
        To infuse this trust and honesty in teams I work with, I focus my
        process around sharing. I have found by sharing things I've learned
        encourages those around me to share. This has given me as much growth as
        I believe my fellow developers have gained from me.
      </p>
      <p>
        My work experience is focused on enterprise level web applications. I
        have full stack experience and am currently focusing on UI development
        using React. Using a variety of related stacks to continue building
        complex, scalable, and robust products for large clients.
      </p>
    </Article>
  );
};
