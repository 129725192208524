import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { About, Intro, Work } from './Articles';

// TODO: Hash mark navigation doesn't seem to work nicely. use routing?

const CloseIcon = styled.i`
  align-self: flex-end;
  position: relative;
  left: 0.6em;
  top: 0.2em;
  color: #ccc;
  font-size: 2em;
  line-height: 2rem;
  cursor: pointer;
`;

class Main extends React.Component {
  // TODO: Close overlay should also be own component.
  handleClose = () => this.props.onCloseArticle();

  render() {
    const { timeout, setWrapperRef, ...articleProps } = this.props;

    const close = <div className="close" onClick={this.handleClose} />;

    return (
      <div
        ref={setWrapperRef}
        id="main"
        style={timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <CloseIcon onClick={this.handleClose}>×</CloseIcon>
        <Intro {...articleProps} />
        <Work {...articleProps} />
        <About {...articleProps} />
        {close}
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
};

export default Main;
