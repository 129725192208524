import React from 'react';

const Article = props => {
  const {
    article,
    articleTimeout,
    children,
    heading,
    id,
    image,
    ...rest
  } = props;

  // TODO: Generate Alt text for images
  // TODO: eliminate CSS

  return (
    <article
      {...rest}
      className={`${article === id ? 'active' : ''} ${
        articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <h2 className="major">{heading}</h2>
      {image && (
        <span className="image main" style={{ marginTop: 0 }}>
          <img src={image} alt="" />
        </span>
      )}
      {children}
    </article>
  );
};

export default Article;
