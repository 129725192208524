import React from 'react';

import Article from './components/Article';
import Subtitle from './components/Subtitle';
import ParagraphHeader from './components/ParagraphHeader';

import pic02 from '../../images/Fireworks-over-Ottawa.jpg';

export default articleProps => {
  return (
    <Article id="work" heading="Work" image={pic02} {...articleProps}>
      <Subtitle>
        Nothing is more constant than change, as in life, in code
      </Subtitle>
      <ParagraphHeader>Present</ParagraphHeader>
      <p>
        I'm currently working primarily with React Based technical stacks. I
        strongly believe in loosely coupling your stack as much as possible
        given today's fast pace library changes. As a result when I approach a
        project I use the mindset of building software that is easy to change.
        One strategy for this is to couple with very few large libraries and
        limiting on boarding additional modules. The most important strategy for
        me is to follow the clean coding and functional programming paradigms.
        <br />
        <br />
        Most recently I have been investing my time learning{' '}
        <a href="https://remix.run/">Remix</a>&nbsp;and thinking about the
        transition back to the server for application state. For the last few
        years I've been using&nbsp;
        <a href="https://mobx.js.org/">MobX & MST</a>,&nbsp; and{' '}
        <a href="https://redux.js.org">Redux</a>&nbsp; for application state
        management while using react internal state and MobX for component state
        management. My favorite thing about the current Javascript ecosystem is{' '}
        <a href="https://styled-components.com">Styled-Components</a>.&nbsp; For
        server related activities in the AWS ecosystem I've really enjoyed the
        freedom <a href="https://aws.amazon.com/lambda/">Lambdas</a> have
        created. Particularly in de-coupling code and processes.
      </p>
      <ParagraphHeader>Past</ParagraphHeader>
      <p>
        &#9;My first taste in grade school was thanks to the book "The Project
        Cool Guide to HTML".
        <br />
        Learning the joy of creating websites on geocities and angelfire and
        more blink text than you can handle, I decided to take a couple CS
        courses in highschool where I scratched the surface of C++. Through a CS
        design course I did a fair amount of Photoshop and Flash animation as
        well. From there the interest grew and I kept a hobby of Photoshop and
        Illustrator through the majority of my undergraduate life.
        <br />
        <br />
        Through University I concurrently earned my Bachelors of Arts with a
        Major in Psychology and my Bachelors of Computer Science. The Co-op
        program during my time there has also provided me with some great
        opportunity for experience.
        <br />
        <br />
        Since graduating I've worked with some great companies on a variety of
        tech stacks including Java front and backend, with javascript libraries
        like Dojo and JQuery, as well as full node.js stacks with Backbone.js
        which has lead me to my current interest in React.
      </p>
      <ParagraphHeader>Future</ParagraphHeader>
      <p>
        There are two main driving forces in my mind that direct my path
        forward. One is the joy of being able to create. The other can be
        summarized by this quote from Heather Downing: "Knowledge hording is the
        bane of our industry...". Thankfully, I feel recently this is becoming a
        common awareness in the industry and more people seek to improve upon
        it.
        <br />
        <br />
        As I continue in this career I focus my growth around building both
        products and teams. These teams of developers may be very new to the
        industry. For us to continually build quality software, we as team
        members should seek to equip other developers so they can contribute and
        grow effectively.
        <br />
        <br />
        Through improving my own coding styles I will continue to build a nature
        of effective contribution into any product I'm involved in. By improving
        process, development workflow, communication, and leadership, I intend
        to help build up any team I'm involved with the desire and ability to
        create software that lasts.
      </p>
    </Article>
  );
};
