import React from 'react';
import styled from 'styled-components';

import Article from './components/Article';
import Subtitle from './components/Subtitle';

import pic03 from '../../images/SlashOnRoofAtCamp.jpg';

const PodcastList = styled.ul`
  margin: 0 0 1rem 0;
  list-style-type: none;
  li {
    margin-left: -1.5rem;
  }
`;

const About = articleProps => {
  return (
    <Article id="about" heading="About" image={pic03} {...articleProps}>
      <Subtitle>life behind bars</Subtitle>
      In my spare time I like to step away from the keyboard and get outside. My
      main hobby is mountain biking, I try to ski as much as I can in the
      winter, and play a bit of paintball.
      <br />
      When it's not as nice outside I enjoy cooking, video gaming, board gaming,
      and listening to podcasts or audio books.
      <br />
      <br />
      <h4>Some of my favorite podcasts:</h4>
      <PodcastList>
        <h5>Career Oriented:</h5>
        <li>
          <a href="https://directory.libsyn.com/shows/view/id/happymelly">
            Happiness at Work by Management 3.0
          </a>
        </li>
        <li>
          <a href="https://6figuredev.com/">The 6 Figure Developer</a>
        </li>
        <li>
          <a href="https://softskills.audio/">Soft Skills Engineering</a>
        </li>
        <li>
          <a href="https://reactpodcast.simplecast.fm/">React Podcast</a>
        </li>
      </PodcastList>
      <PodcastList>
        <h5>Android: Netrunner card game</h5>
        <li>
          <a href="https://www.idlethumbs.net/terminal7">Terminal 7</a>
        </li>
        <li>
          <a href="http://runlastclick.blogspot.com/">Run Last Click</a>
        </li>
        <li>
          <a href="https://www.mixcloud.com/tagme-anetrunnerpodcast-northe/">
            TagMe (A podcast I co-hosted)
          </a>
        </li>
      </PodcastList>
      <Subtitle>GitHub</Subtitle>
      <ul className="icons" style={{ marginTop: '0' }}>
        <li>
          <a href="https://github.com/abharvey" className="icon fa-github">
            <span className="label">GitHub</span>
          </a>
        </li>
      </ul>
    </Article>
  );
};

export default About;
