import React from 'react';

import BackgroundImage from 'gatsby-background-image';

import MainWrapper from '../components/MainWrapper';

class IndexPage extends React.PureComponent {
  render() {
    const background = this.props.data.allFile.edges[0].node.childImageSharp
      .fluid;

    return (
      <BackgroundImage fluid={background} style={{ maxHeight: '100vh' }}>
        <MainWrapper />
      </BackgroundImage>
    );
  }
}

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "mainBG" } }) {
      edges {
        node {
          childImageSharp {
            fluid(traceSVG: { color: "#1E2151" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
